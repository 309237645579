<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />
    <v-layout>
      <v-flex xs12>
        <span class="bold"> <h3>User Sightings Details</h3></span>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-card width="100%" tile>
          <v-layout wrap>
            <v-flex xs12 sm12 class="subhead">
              <v-card tile height="100%" color="#b3b3b3">
                <v-layout wrap pa-4>
                  <v-flex xs12> </v-flex>
                  <v-flex xs12 md2>
                    <span class="sbold"> User Name </span> &nbsp;
                  </v-flex>
                  <v-flex xs10>
                    <span class="regular">
                      {{ user.first_name }}
                      {{ user.last_name }}</span
                    >
                  </v-flex>
                  <v-flex xs12 md2>
                    <span class="sbold"> Phone </span> &nbsp;
                  </v-flex>
                  <v-flex xs10>
                    <span class="regular"> {{ user.phone }}</span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 sm12 lg6class="subhead" text-center align-self-center>
              <v-card tile color="#e6e6e6">
                <v-layout wrap pa-2 text-left>
                  <v-flex xs12 md6 align-self-center>
                    <v-layout wrap px-3>
                      <v-flex xs6>
                        <span class="sbold">Boat Name </span>
                      </v-flex>
                      <v-flex xs6>
                        <span class="regular">{{ records.boatname }}</span>
                      </v-flex>
                      <v-flex xs6>
                        <span class="sbold">Boat No </span>
                      </v-flex>
                      <v-flex xs6>
                        <span class="regular">{{ records.boatno }}</span>
                      </v-flex>
              
                      <v-flex xs6 v-if="records.ownerName">
                        <span class="sbold">Boat Owner Name </span>
                      </v-flex>

                      <v-flex xs6 v-if="records.ownerName">
                        <span class="regular">{{ records.ownerName }}</span>
                      </v-flex>
                       <v-flex xs6>
                        <span class="sbold">Date </span>
                      </v-flex>
                      <v-flex xs6>
                        <span class="regular">{{  ChangeFormateDate(records.create_date) }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex
                    xs12 lg6

                    :style="
                      $vuetify.breakpoint.name != 'xs'
                        ? 'border-left: 3px dashed black '
                        : ''
                    "
                  >
                    <v-layout wrap px-3 pa-0 pa-lg-5>
                      <v-flex xs6 class="sbold"> Type</v-flex>
                      <v-flex xs6 class="regular"> {{ records.type }} </v-flex>
                      <v-flex xs6 class="sbold"> Found - On </v-flex>
                      <v-flex xs6 class="regular"> {{ records.field }} </v-flex>
                      <v-flex xs6 class="sbold">Gender </v-flex>
                      <v-flex xs6 class="regular">
                        {{ records.gender }}
                      </v-flex>
                      <v-flex xs6 class="sbold"> Health Status</v-flex>
                      <v-flex xs6 class="regular">
                        {{ records.health }}
                      </v-flex>
                      <v-flex xs6 class="sbold"> Expected Length (M)</v-flex>
                      <v-flex xs6 class="regular">
                        {{ records.length }}
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs12 pt-2 v-if="records.description != null">
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 pl-3 v-if="records.description != null">
                    <span class="sbold" style=""> Description :</span> <br />
                    <p>{{ records.description }}</p>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
       
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-2>
      <v-flex xs12 id="googlemap2"> </v-flex>
    </v-layout>
      <v-layout wrap v-if="boatDocument != null">
      <v-flex xs12 pt-3>
        <span class="bold"> <h3>Boat document</h3></span> 
      </v-flex>
      <v-flex>
        <v-layout wrap>
          <v-flex xs12>
            <v-card outlined>
              <v-flex xs12 sm6 pa-3 >
                <v-img
                  width="100%"
                  height="400px"
                  contain
                  :src="mediaURL + boatDocument[0]"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color=" #FDAC53"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-flex>
            
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="photos.length > 0">
      <v-flex xs12 pt-3>
        <span class="bold"> <h3> Rescue Photos</h3></span>
      </v-flex>
      <v-flex>
        <v-layout wrap>
          <v-flex xs12>
            <v-card outlined>
              <v-layout wrap>
              <v-flex xs12 sm6 lg4 pa-3 v-if="photos[0]">
                <v-img
                  max-width="100%"
                  max-height="400px"
                  contain
                  :src="mediaURL + photos[0]"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color=" #FDAC53"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-flex>
              <v-flex xs12 sm6 lg4 pa-3 v-if="photos[1]">
                <v-img
                  max-width="100%"
                  max-height="400px"
                  contain
                  :src="mediaURL + photos[1]"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color=" #FDAC53"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-flex>
               <v-flex xs12 sm6 lg4 pa-3 v-if="photos[2]">
                <v-img
                  max-width="100%"
                  max-height="400px"
                  contain
                  :src="mediaURL + photos[2]"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color=" #FDAC53"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-flex>
              </v-layout>

            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="video">
      <v-flex xs12 pt-3>
        <span class="bold"> <h3>Rescue video</h3></span>
      </v-flex>
      <v-flex>
        <v-layout wrap>
          <v-flex xs12>
            <v-card outlined>
              <v-flex xs12  pa-3>
                <iframe
                  height="300"
                  :src="mediaURL + video"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  width="100%"
                >
                </iframe>
              </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=
AIzaSyB-wSOSvoBXfA5vn-23GR7OU5qTm8BFwlk&libraries=visualization,drawing">
</script>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      records: [],
      gmap2: null,
      latt: null,
      lonn: null,
      icons: "",
      photos: [],
      user: [],
      video: null,
      boatDocument:null,
    };
  },
  mounted() {
    this.getData();
    this.getMap();
  },
  methods: {

   ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }
        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];
        return month + "/" + day + "/" + year;
      }
    },


    getMap() {
      this.gmap2 = new google.maps.Map(document.getElementById("googlemap2"), {
        center: { lat: 9.5260093, lng: 76.8144186 },
        zoom: 2,
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/record/view/each",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 25,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.user = response.data.user;
            if( response.data.data.boatDocument.length >0)
            {
            this.boatDocument = response.data.data.boatDocument;

            }
            this.records = response.data.data;
            this.user = response.data.user;
            this.photos = response.data.data.photos;
            this.video = response.data.data.rescueVideo;
            this.latt = this.records.location[1];
            this.lonn = this.records.location[0];
            this.marker = new google.maps.Marker({
              position: { lat: this.latt, lng: this.lonn },
              map: this.gmap2,
              icon: this.icons,
            });
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
#googlemap2 {
  height: 400px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
</style>